@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:ital,wght@0,300..700;1,300..700&display=swap');

/***************
Variables
***************/

$green: #ccd5ae;
$green-light: #e9edc9;
$green-font: #24261f;
$white: #f8f7f3;
$brown-light: #faedcd;
$brown-font: #6c584c;
$brown: #d4a373;

$font-family-fancy: "Poppins", sans-serif;
$font-family-normal: "Spline Sans Mono", monospace;

$transition: .5s ease;

$box-shadow: 0 4px 3px 1px $white, 0 6px 8px #c6bbb5, 0 -4px 4px #cec1b9, 0 -6px 4px $white, inset 0 0 3px 0 #CECFD1;
$box-shadow-hover: 0 4px 3px 1px $white, 0 6px 8px #c6bbb5, 0 -4px 4px #cec1b9, 0 -6px 4px $white, inset 0 0 3px 3px #CECFD1;
$border: 1px solid #a8a7a6;
$background-image: linear-gradient(to top, #cec1b9 0%, #fff 80%, $white 100%);

/***************
Responsive
***************/

@mixin mobile(){
    @media screen and (max-width: 767px) {
        @content;
    }
}
/***************
Mixins
***************/

@mixin boxShadow(){
    box-shadow: $box-shadow;
    background-image: $background-image;
    border: $border;
    transition: $transition;
}
@mixin boxShadowHover(){
    &:hover{
        box-shadow: $box-shadow-hover;
    }
}

@mixin linkHover(){
    transition: $transition;
    background-image: linear-gradient($brown-font, $brown-font);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0% 2.5px;
    transition: background-size $transition;
    width: fit-content;
    cursor: pointer;
    &:hover{
        background-size: 100% 2.5px;
        background-position: bottom left;
    }
}

@mixin linkHoverUnderline(){
    transition: $transition;
    background-image: linear-gradient($brown-font, $brown-font);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 100% 2.5px;
    transition: background-size $transition;
    width: fit-content;
    cursor: pointer;
    &:hover{
        background-size: 0% 2.5px;
        background-position: bottom left;
    }
}
/***************
Settings
***************/

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body{
    font-family: $font-family-normal;
    background-color: $white;
    color: $green-font;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;

    a{
        text-decoration: none;
        color: inherit;
        padding-bottom: 2px;
        &.link_underline{
            @include linkHoverUnderline();
        }
    }
    li{
        list-style: none;
    }

    button{
        background-color: transparent;
        border: 0;
    }
    img{
        border-radius: 20px;
        box-shadow: $box-shadow;
        max-width: 100%;
        object-fit: cover;
    }
}

/***************
Fonts
***************/

body{
    h1, h3, h5{
        font-weight: 500;
    }
    h2, h4, h6{
        font-weight: 500;
    }
    h1{
        font-size: 45px;
    }
    h2{
        font-size: 35px;
    }
    h3{
        font-size: 25px;
    }
    h4,h5,h6{
        font-size: 20px;
    }
    p, div{
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: .7px;
    }
    a, button{
        font-size: 15px;
        line-height: 1.3;
        letter-spacing: .7px;
    }
    @include mobile(){
        a, button{font-size: 13px;}
    }
}
/***************
Spacing
***************/

body{
    .container{
        padding-inline: 40px;
        padding-block: 8rem;
        @include mobile(){
            padding-inline: 20px;
        }
    }
}