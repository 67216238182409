.popup{
    background-color: $white;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    overflow-y: scroll;
    width: 60vw;
    z-index: 10;
    transform: translateX(65vw);
    transition: $transition;
    padding: 40px;
    box-shadow: $box-shadow;
    border: $border;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .btn{
        width: 50px;
        height: 25px;
        padding: 0;
    }
    &.show{
        transform: translateX(0);
    }
    @include mobile(){
        width: 100vw;
        transform: translateX(105vw);
        padding: 20px;
    }
}