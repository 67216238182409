#experience{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .tabs{
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 30px;
        @include boxShadow();
        .tab{
            @include linkHover();
            &.active{
                background-size: 100% 2.5px;
                background-position: bottom left;
            }
        }
    }
    .accordion{
        margin-top: 30px;
        width: 80%;
        .item{
            overflow: hidden;
            .title{
                cursor: pointer;
                padding: 20px 40px;
                @include boxShadow();
            }
            .content{
                background: none;
                display: none;
                padding: 40px;
                box-shadow: $box-shadow;
                border: $border;
                p{
                    margin-bottom: 20px;
                }
    
            }
            &.active .content{
                display: block;
            }
        }
    }

    @include mobile(){
        .accordion{
            width: 100%;
            .item{
                .title, .content{
                    padding: 20px;
                }
            }
        }
    }
}

.skills-banner{
    padding-block: 20px;
    display: flex;
    // gap: 80px;
    width: fit-content;
    overflow-x: hidden;
    max-width: 100vw;
    & >div{
        display: flex;
        justify-content: space-between;
        min-width: 100vw;
        animation: bannerScroll 10s linear infinite;
        padding-left: 80px;
        gap: 80px;
        .skill{
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    @media screen and (max-width: 1000px){
        & > div{
            animation: bannerScrollMobile 7s linear alternate infinite;
            &:last-child{
                display: none;
            }
        }
    }
}

@keyframes bannerScroll {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-100%);
    }
}

@keyframes bannerScrollMobile {
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(-50%);
    }
}